<template>
	<div class="myinvite">
		<div class="tabbox">
			<div :class="['tabitem', tabidx==0?'tabckd':'']" @click="tabidx=0">已购商品</div>
			<div :class="['tabitem', tabidx==1?'tabckd':'']" @click="tabidx=1">邀请好友</div>
		</div>
		<div v-show="tabidx==0">
			<div class="title">好友购买的商品</div>
			<div v-for="item of ShowList" class="oneorder">
				<div class="ordernum">
					{{item.orderid}}
				</div>
				<div class="orders" v-for="p of item.children">
					<div class="proimg flexcenter">
						<img :src="p.headimg">
					</div>
					<div class="proInfo">
						<div class="pros">
							<div class="proname">
								{{p.title}}
							</div>
							<div class="counts">
								<div class="price">{{ p.price|filtermoney}}<span> &times; {{p.number}}</span></div>
							</div>
						</div>
					</div>
				</div>
				<div style="padding: 0 20px; background: #f6fafe;">
					<div class="frees">合计：{{item.totalmoney|filtermoney}}，返我积分:{{item.usescore}}
					</div>

				</div>
			</div>

			<div style="height: 80px; text-align: center; line-height: 80px;" v-show="DataList.length==0">
				你的好友还没有购买任何东西
			</div>
		</div>
		<div v-show="tabidx==1">
			<div class="inviteList" v-for="item of InviteList" @click="ShowFriend(item)">
				<div class="img flexcenter">
					<img :src="item.headimgurl" height="60" width="60" />
				</div>
				<div class="info">
					<div class="name">{{item.nickname}}</div>
					<div class="counts">
						<div class="count_left">日期：{{item.cdate}}</div>

					</div>
					<div>
						<div class="count_left">消费总额<span style="color: #007AFF;">￥{{item.tmoney==''?'0':parseFloat(item.tmoney).toFixed(2)}}</span>，邀请好友<span style="color: #007AFF;">{{item.invitecount}}</span>人</div>

					</div>
				</div>
			</div>

			<div style="height: 80px; text-align: center; line-height: 80px;" v-show="InviteList.length==0">
				还没有邀请好友
			</div>
		</div>

	</div>

</template>

<script>
	export default {
		data() {
			return {
				DataList: [],
				ShowList: [],
				tabidx: 0,
				InviteList: [],
			}
		},
		mounted() {
			this.GetMy()
		},
		filters: {
			filtermoney: function(value) {
				if(!isNaN(value)) {
					value = parseFloat(value)
					return '￥' + value.toFixed(2);
				}

			}
		},
		methods: {
			GetMy() {
				this.$store.dispatch("GET", {
					url: "/api/wx/?action=friend&uid=" + this.$route.params.id
				}).then(result => {
					let orderid = "";
					let data = [];
					this.ShowList = [];
					for(let item of result.data.data.goods.rows) {
						let ext = false
						let exto = null;
						for(let o of data) {
							if(item.orderid == o.orderid) {
								ext = true;
								exto = o;

								break;
							}

						}
						if(!ext) {
							data.push({
								orderid: item.orderid,
								paystatus: item.paystatus,
								totalmoney: item.totalmoney,
								expressprice: item.expressprice,
								usescore: item.backscore2,
								gooddesc: item.gooddesc,
								comment: item.comment,
								activity: item.activity,
								children: [item]
							})
						} else {
							if(exto.usescore == "") {
								exto.usescore = 0
							}
							if(item.backscore2 != "") {
								exto.usescore += parseInt(item.backscore2);
							}
							exto.children.push(item)
						}
					}
					
					this.DataList = data;
					for(let item of this.DataList) {
						if(item.paystatus != -1) {
							this.ShowList.push(item)
						}
					}
					
					
					
					
					for(let item of result.data.data.invent.rows){
						if(item.tmoney==''){
							item.tmoney=0;
						}
						if(item.invitecount==''){
							item.invitecount=0;
						}
						
						
					}
					
					this.InviteList = result.data.data.invent.rows
					
					
				})
			}

		}
	}
</script>

<style scoped="scoped">
	.myinvite {
		height: 100%;
		width: 100%;
	}
	
	.title {
		height: 42px;
		line-height: 42px;
		background: #94bdf9;
		font-size: 14px;
		color: #FFFFFF;
		padding-left: 20px;
	}
	
	.inviteList {
		background: #f6fafe;
		height: 76px;
		padding-left: 15px;
		padding-right: 15px;
		display: flex;
		margin-top: 15px;
	}
	
	.img {
		width: 62px;
	}
	
	.img img {
		border: 1px solid #FFFFFF;
		border-radius: 50%;
	}
	
	.info {
		flex: 1;
		margin-left: 20px;
	}
	
	.name {
		font-size: 17px;
		color: #474747;
		margin-top: 15px;
	}
	
	.counts {
		display: flex;
		margin-top: 7px;
	}
	
	.count_left {
		font-size: 15px;
		color: #a4a4a4;
		flex: 1;
	}
	
	.count_right {
		font-size: 14px;
		color: #979899;
	}
	
	.count_right span {
		color: #d92b2b;
	}
	
	.orderList {
		height: 100%;
		width: 100%;
	}
	
	.title {
		height: 38px;
		line-height: 38px;
		background: #f6fafe;
		display: flex;
		font-size: 14px;
		color: #8f8f8f;
		font-weight: bold;
	}
	
	.item {
		flex: 1;
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.active {
		color: #5c91e2;
		border-bottom: 2px solid #26a1ff;
	}
	
	.ordernum {
		background: #f6fafe;
		padding: 10px 20px;
		border-bottom: 1px solid #dcdcdc;
		margin-top: 10px;
	}
	
	.orders {
		height: 88px;
		background: #f6fafe;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
	}
	
	.proimg {
		width: 80px;
	}
	
	.proimg>img {
		width: 90%;
		height: 90%;
	}
	
	.proInfo {
		flex: 1;
		margin-left: 15px;
	}
	
	.pros {
		height: 78px;
	}
	
	.counts {
		font-size: 12px;
		color: #a4a4a4;
		border-bottom: 1px solid #dcdcdc;
		padding-bottom: 10px;
	}
	
	.proname {
		padding: 8px 0;
		display: flex;
		height: 38px;
		overflow: hidden;
	}
	
	.name {
		flex: 1;
		font-size: 13px;
		color: #7b7b7b;
		overflow: hidden;
	}
	
	.price {
		font-size: 15px;
		color: #26a1ff;
		text-align: right;
	}
	
	.frees {
		text-align: right;
		font-size: 14px;
		color: #7b7b7b;
		padding-top: 10px;
	}
	
	.oneorder .counts:nth-last-child {
		border-bottom: 1px solid red
	}
	
	.buttons {
		display: flex;
		height: 35px;
		padding-top: 10px;
	}
	
	.wuli {
		margin-left: 10px;
		font-size: 12px;
		width: 68px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		border: 1px solid #ddd;
		border-radius: 15px;
	}
	
	.shh {
		margin-left: 10px;
		font-size: 12px;
		color: #30a5ff;
		width: 68px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		border: 1px solid #43aeff;
		border-radius: 15px;
	}
	
	.tabitem {
		float: left;
		width: 50%;
		text-align: center;
		padding: 10px 0;
	}
	
	.tabckd {
		background: #007AFF;
		color: #fff;
	}
</style>